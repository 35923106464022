<template>
  <div class="RechargeTips">
    <div class="main">
      <div class="icon-wrapper">
        <div class="loading-wrapper">
          <van-loading
            v-if="!isSuccess"
            class="loading"
            :size="'14vw'"
          />
        </div>
        <div class="success-wrapper">
          <transition name="scale">
            <div
              v-if="isSuccess"
              class="success"
            >
              <div class="level-1">
                <div class="level-2" />
              </div>
            </div>
          </transition>
          <div
            v-if="isSuccess"
            class="level-3"
          />
        </div>
      </div>
      <div class="tips">
        <div
          v-for="(item, index) in tips"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div class="button-wrapper">
        <template v-if="!isSuccess">
          <van-button
            class="buttons"
            @click="handleResetCount"
          >
            Refresh
          </van-button>
          <!-- <van-button
            plain
            type="primary"
            class="buttons Contact "
          >
            Contact Us
          </van-button> -->
        </template>
        <template v-else>
          <van-button
            class="buttons"
            @click="handleBack"
          >
            PLAY NOW
          </van-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { subscriptionSuccess } from '@/utils'
import { Button, Loading } from 'vant'
import { getUserWallet } from '@/api/pay.js'
export default {
  name: 'Waitting',
  components: {
    VanButton: Button,
    VanLoading: Loading
  },
  data () {
    return {
      count: 60,
      waittingTips: [
        'The payment result is being confirmed…',
        `It takes about ${this.count}S…`
      ],
      successTips: [
        'Congrattulations!',
        'Go pick a game and play it!'
      ],
      timer: '',
      wallet: {}
    }
  },
  computed: {
    isSuccess () {
      return this.wallet.isSubscription && this.wallet.totalTime
    },
    tips () {
      return this.isSuccess ? this.successTips : [
        'The payment result is being confirmed…',
        `It takes about ${this.count}s…`
      ]
    }
  },
  created () {
    this.loopAskWallet()
    this.countDown()
  },
  methods: {
    getUserWallet () {
      getUserWallet()
        .then(res => {
          if (res.code === 200) {
            this.wallet = res.data
          }
        })
    },
    countDown () {
      setTimeout(() => {
        if (this.count > 2) {
          this.count--
          this.countDown()
        }
      }, 1000)
    },
    handleBack () {
      const data = JSON.parse(this.$route.query.payload)
      subscriptionSuccess(data)
        .then(res => {
          console.log(res)
        })
    },
    handleResetCount () {
      this.count = 60
    },
    loopAskWallet () {
      if (!this.isSuccess) {
        this.getUserWallet()
        this.timer = setTimeout(() => {
          this.loopAskWallet()
        }, 3000)
      } else {
        clearTimeout(this.timer)
        this.handleBack()
      }
    }
  }
}
</script>

<style lang="less">
  .scale-enter-active, .scale-leave-active {
    transition: transform .3s ease-in-out;
  }
  .scale-enter, .scale-leave-to /* .fade-leave-active below version 2.1.8 */ {
    transform: scale(.8);
  }
  .RechargeTips{
    .van-button--default {
      background: linear-gradient(270deg, #FFBB1D 0%, #FFCC37 100%) ;
      border-radius: 10px !important ;
      font-weight: bolder;
      font-size: 30px !important;
    }
    .main{
      padding: 88px 75px 0;
      box-sizing: border-box;
      .icon-wrapper{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 485px;
        padding-bottom: 80px;
        box-sizing: border-box;
        position: relative;
        .loading-wrapper,
        .success-wrapper{
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translate(-50%, -150%);
        }
        .flex-center () {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .loading-wrapper{
          width: 180px;
          height: 180px;
          .flex-center;
        }
        .success-wrapper{
          width: 180px;
          height: 180px;
          .success{
            .flex-center;
            width: 180px;
            height: 180px;
            position: relative;
            .level-1{
              .flex-center;
              width: 180px;
              height: 180px;
              border-radius: 180px;
              background: #d6f7dd;
              .level-2{
                .flex-center;
                width: 136px;
                height: 136px;
                border-radius: 136px;
                background: #95eaa7;
              }
            }
          }
          .level-3{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90px;
            height: 90px;
            border-radius: 90px;
            background: url('~@/assets/img/success.png') ~'100% / 100%' no-repeat;
          }
        }
      }
      .tips{
        font-size: 28px;
        color: #999999;
        line-height: 54px;
        text-align: center;
        height: 175px;
      }
      .button-wrapper{
        width: 100%;
        .buttons{
          width: 100%;
          height: 90px;
          margin-bottom: 40px;
          &.Contact{
            color: #FFCC37;
            border: 1px solid #FFCC37;
            border-radius: 10px;
          }
        }
      }
    }
  }
</style>
